@import "mixins";

// TODO these are all broken after much refactoring ignorant of prints

// global
@media print {

  * {
    margin: 0;
    padding: 0;
    border: none;
  }

  video, audio, object, embed, iframe {
    display: none;
  }

  header.brand, .search-drawer, .breadcrumb, .search-methods {
    display: none;
  }
  

  .tooltip {
    display: none;
  }

  // Every parent element of the img-wrapper must have a set height relative to the page height, if we want to
  // adjust the img height relative to the page.
  html, body, .js-content {
    height: 100%;
  }

  .print-only.header {
    @include clearFix;
    text-align: center;
    font-size: 12px;
    margin-bottom: 1ex;
    height: 15px;

    .left {
      float: left;
    }

    .right {
      float: right;
    }
  }

  // binding view

  .overlay.toolbar, .overlay.citation-info, .overlay.page-navigation, .overlay.overlay-form, #bindingProblemsModal, .image-overlay .overlay.restricted-warning {
    display: none;
  }

  .user-article {
    display: none;
  }

  // is a sibling of .print-only.header
  div[ng-controller=BindingRootController] {
    height: calc(100% - 25px);
    width: 100%;
  }

  div[ng-controller=BindingController], section.page-holder {
    height: 100%;
  }

  .image-wrapper {
    // override javascript zoom effects
    width: 100% !important;
    height: 100% !important;
    text-align: center;

    img {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  // clipping view

  .clipping-view {

    a {
      text-decoration: none;

      img {
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
  }

  .tabs-container, .clipping-actions, input, .write-comment, .citation-info {
    display: none;
  }

  .clipping-metadata {
    h1 {
      font-size: large;
    }

    // hack, add classes to markup for better control
    div {
      height: auto !important;
      margin: 0 !important;
    }
  }


  // browse-titles

  .browse-all-titles {


    .list-group-item {
      @include clearFix;

      .title-details {
        @include clearFix;
        float: right;

        .content-amount-indicators {
          margin-right: 2em;
          min-width: 5em;
          display: inline-block;
          text-align: right;
        }

        .identification {
          margin-left: 4em;
          min-width: 5em;
          display: inline-block;
        }
      }

      // hide urls that are made visible for printing by default
      a[href]:after {
        content: none;
      }

      .identification a, .page-count-indicator {
        display: none;
      }
    }
  }
}
