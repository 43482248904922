
@mixin clearFix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// TODO configure autoprefixer

@mixin borderRadius($value) {
  -ms-border-radius: $value;
  border-radius: $value;
}
