// backgrounds
$kkBgDarkGray: #2a2a2a;
$kkBgDarkBlue: #00224f;
$kkBgBlue: #005898;
$kkBgWhite: white;
$kkBgVeryLight: rgb(245, 246, 245);

$kkButtonBlue: #1177C5;
$kkBadgeBlue: darken(#178feb, 15%);

// text, headers, links, etc.
$kkTextDarkGray: #424242;
$kkTextMediumGray: #666;
$kkTextMediumLightGray: #8f8e8e;
$kkTextLightGray: #e6e6e6;

$kkButtonMainLightGray: #61686f;
$kkButtonBorderLightGray: #ced4da;
$kkButtonBgLightGray: #f8f9fa;

$kkLinkBasicBlue: #178feb;
$kkLinkBlue: darken(#178feb, 10%);

$kkLinkBlueActive: darkblue;
$kkLinkBlack: rgb(66, 66, 66);
$kkLinkLight: $kkTextLightGray;
$kkLinkLightActive: white;

$kkFeatureActive: #ff5722;

// new stuff

$kkDarkBlue2021: #002855;
$kkDarkBlueBorder: #0B2852;
$kkBlue2021: #0D2FC4;
$kkLightGray2021: #EFF1F4;
$kkMediumGray2021: #DAE0E6;
$kkDarkGray2021: #C2CBD6;

$kkLink2021: $kkBlue2021;
$kkLinkActive2021: #1133BC;
$kkLinkVisited2021: #651c85;

$kkDarkRose: #E29D92;
$kkMediumRose: #F4D9D5;
$kkLightRose: #FBF2F1;
$kkDarkTeal: #1C7291;
$kkMediumTeal: #C0DDE6;
$kkLightTeal: #ECF5F7;
$kkSilver60: #CFCFCF;

$kkRed40 : #FDDEDA;


$kkRed: #D10F0F;
$kkGreen: #06884C;
$kkYellow: #F7DB8B;

// #495057; bootstrap form-control color, too light with grey background
$kkFormControlLabel: $kkDarkBlue2021;

// 2022 style guide colors
$kkTiili : #A0270A;
$kkRuusu: #D6958B;
$kkHanami : #EBC1B9;
$kkPuuteri : #F7DEDA;
$kkSilver40: #E6E6E6;
$kkSilver80: #A0A0A0;


$kkBlue100: $kkDarkBlue2021;
$kkBlue80: #5D7999;
$kkBlue60: #B8C9DB;
$kkBlue40: #DFE5ED;



// 2022 style guide colors
$kkTiili: #A0270A;
$kkRuusu: #D6958B;
$kkHanami: #EBC1B9;
$kkPuuteri: #F7DEDA;
$kkSilver40: #E6E6E6;
$kkSilver80: #A0A0A0;

$kkBlue80: #5D7999;
$kkBlue60: #B8C9DB;
$kkBlue40: #DFE5ED;
