.cursor-progress {
  cursor: progress !important;
}

.mouse-crosshair {
  cursor: crosshair;
}

.mouse-move {
  cursor: move;
}

.mouse-ns-resize {
  cursor: ns-resize;
}

.mouse-ew-resize {
  cursor: ew-resize;
}

.mouse-nesw-resize {
  cursor: nesw-resize;
}

.mouse-nwse-resize {
  cursor: nwse-resize;
}

.form-saving-true, .form-saving-true input, .form-saving-true button {
  cursor: progress !important;
}
