// organize this stuff better

@import "mixins";

div.caption {

  padding-top: 2rem;

  h3 {
    font-size: 1.2rem;
  }
}

.absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay {
  z-index: 11;
}

time {
  white-space: nowrap;
}

.multi-column-results {
  list-style-type: none;
  padding-left: 0;
  column-width: 20em;
}

.two-column-results {
  column-count: 2
}

// prevent list-item borders from breaking
// list-group-item is a bootstrap class
.two-column-results, .multi-column-results {
  li, .list-group-item {
    break-inside: avoid;
  }
}

.year-columns {
  margin: 0 auto;
  width: 30em;
  column-count: 6
}

ul.alphabet-columns, ul.centuries, ul.years {
  margin: 1em;
  text-align: center;
  list-style-type: none;

  > li {
    display: inline;
    margin-right: 1em;

    a {
      font-weight: bold;
    }
  }
}

.validation-errors {
  color: #ac0000;
}

.saved-item {
  margin-bottom: 15px;
}

.personal-notes {
  margin-top: 2em;
  margin-bottom: 2em;
}

input.error {
  border: 1px solid red;
}

.puls {
  animation: pulsate 4s linear infinite;
}

@keyframes pulsate {
  0% {
    color: #ddd;
    text-shadow: 0 -1px 0 #000;
  }
  50% {
    color: #fff;
    text-shadow: 0 -1px 0 #444, 0 0 5px #ffd, 0 0 8px #fff;
  }
  100% {
    color: #ddd;
    text-shadow: 0 -1px 0 #000;
  }
}

.userarticlesearch {
  margin: 0 auto;
  padding-bottom: 11px;
  padding-top: 11px;
  width: 96%;
  clear: both;
}

.scrapbook-items {
  list-style-type: none;
  margin: 0;
  padding-top: 3px;
  padding-bottom: 3px;
}

.scrapbook-items > li {
  &:nth-child(2n+1) {
    background-color: #d9d3c5;
  }

  &.hover-true {
    text-decoration: underline;
  }
}

.searcharea {
  width: 250px;
}

.list-group-item {
  background-color: transparent;
}

.forgotten-links {
  margin: auto;
  text-align: center;

  a {
    margin: 0 10px;
  }
}

.required-indicator {
  color: #D10000;
}

.opendata-usage {
  // border: 1px solid #337ab7;
  // padding: 1ex 0;

  &:not(:last-child) {
    margin-bottom: 1ex;
  }

  @include clearFix();
  @include borderRadius(3px);
}
