@import "./colors";

// styles necessary for 3rd party components

.dropdown-menu {
  width: inherit;
}

// Make ng-cloak items hidden until AngularJS initializes them
[ng\:cloak], [ng-cloak], .ng-cloak, .translate-cloak {
  display: none !important;
}

// Fullscreen container for Bootstrap
.container-full-width {
  padding: 0 15px;
}

.container-almost-full-width {
  padding: 0 42px;
}

.btn-kk {
  $mainColor: $kkButtonMainLightGray;
  $borderColor: $kkButtonBorderLightGray;
  $bgColor: $kkButtonBgLightGray;

  color: $mainColor;
  //noinspection CssOptimizeSimilarProperties
  background-color: $bgColor;
  //noinspection CssOptimizeSimilarProperties
  background-image: none;
  border-color: $borderColor;

  &:hover {
    color: darken($mainColor, 20%);
    border-color: darken($borderColor, 20%);
    background-color: darken($bgColor, 5%);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba($mainColor, 0.5);
  }

  &.disabled, &:disabled {
    color: $mainColor;
    background-color: transparent;
  }
}


@mixin kkButton(
  $bgColor: DarkGray, 
  $color: white, 
  $borderColor: $bgColor, 
  $hoverColor: $color, 
  $hoverBgColor: $bgColor, 
  $hoverBorderColor: $hoverBgColor) {

  color: $color;
  //noinspection CssOptimizeSimilarProperties
  background-color: $bgColor;
  //noinspection CssOptimizeSimilarProperties
  background-image: none;
  border-color: $borderColor;
  font-weight: normal;

  &:hover {
    color: $hoverColor;
    background-color: $hoverBgColor;
    border-color: $hoverBorderColor;
  }

  &:focus, &.focus {
    // box-shadow: 0 0 0 0.2rem rgba($color, 0.5);
    box-shadow: none;
    outline: 2px solid $kkBlue2021;
    outline-offset: 3px;
  }

  &.disabled, &:disabled {
    color: $kkDarkBlue2021;
    background-color: $kkMediumGray2021;
    border-color: $kkMediumGray2021;
    font-weight: 300;
    cursor: not-allowed;
  }
}

@mixin kkButtonDarkBlue {
  @include kkButton($color: white, $bgColor: $kkBlue2021, $hoverBgColor: $kkDarkBlue2021 );
}

.btn-kk-blue {
  @include kkButtonDarkBlue;
  border-radius: 4px;
}

@mixin kkButtonGray {
  @include kkButton($color: $kkDarkBlue2021, $bgColor: $kkMediumGray2021, $hoverColor: $kkDarkBlue2021, $hoverBgColor: $kkDarkGray2021);
}
.btn-kk-gray {
  @include kkButtonGray
}

@mixin kkButtonTeal {
  @include kkButton($color: $kkDarkBlue2021, $bgColor: $kkMediumTeal, $hoverColor: white, $hoverBgColor: $kkDarkTeal);
}
.btn-kk-teal {
  @include kkButtonTeal
}

@mixin kkButtonLight {
  @include kkButton($color: $kkDarkBlue2021, $bgColor: white, $hoverBgColor: $kkDarkRose);
}
.btn-kk-light {
  @include kkButtonLight
}

.btn-kk-border {
  border-color: #0B2852;
}

.btn-kk-red {
  @include kkButton($color: white, $bgColor: $kkRed, $hoverColor: $kkDarkBlue2021, $hoverBgColor: $kkDarkRose);
}

.btn-kk-rose {
  @include kkButton($color: $kkDarkBlue2021, $bgColor: $kkMediumRose, $hoverColor: $kkDarkBlue2021, $hoverBgColor: $kkDarkRose);
}


.badge-kk-blue {
  color: #fff;
  background-color: $kkBadgeBlue;

  &[href]:focus, &[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: $kkBadgeBlue;
  }
}

.btn-link, .btn-kk-link {
  font-weight: 400;
  color: $kkLink2021;
  text-decoration: none;

  &:hover {
    color: $kkDarkBlue2021;
  }
}

.page-link {
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  line-height: 2.3rem;
  padding: 0 12px;
  color: $kkBlue2021;
  
  &:hover {
    color: $kkDarkBlue2021;
  }
}

.page-item.active .page-link {
  background-color: $kkBlue2021;
  border-color: $kkBlue2021;
}

.page-item.disabled:hover {
  cursor: not-allowed;
}

ngb-datepicker {
  width: auto !important;

  ngb-datepicker-navigation-select {
    width: 10rem !important;

    select:first-of-type {
      width: 50%!important;
      margin-right: 0.3rem;
    }

    select:last-of-type {
      width: 45%!important;
    }
  }
}


// jira collector has fixed width that is way too big for mobile screens and as a bonus it doesn't allow horizontal scrolling
@media screen and (max-width: 810px) {
  .atlwdg-popup {
    width: 97%!important; // original is 810px
    height: 97%!important;
    max-height: none!important;
  }
}

// Disable blue outline on focus on angular-material buttons.
// Use a broad rule, since we probably don't want any other kind of outlines either.
button:focus {
  // outline: none!important; FIXME check
}

.form-control {
  color: $kkDarkBlue2021;
  
  &:focus {
    color: $kkDarkBlue2021;
  }
  
  &::placeholder {
    color: $kkDarkBlue2021;
  }
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: $kkDarkBlue2021;
}

.nav-tabs.kk-nav-tabs {
  $borderColor: $kkButtonBorderLightGray;
  $hoverColor: darken($borderColor, 20%);
  
  border-color: $borderColor;
  
  .nav-link:hover {
    border-color: $hoverColor $hoverColor $borderColor;
  }
  
  .nav-link.active {
    border-color: $borderColor $borderColor #fff;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 33.3px!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-checked {
  background-color: $kkBlue2021!important;
  color: white!important;
}