@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import './kk-2021.palettes';
@import '../_fonts';

$custom-typography: mat.define-typography-config(
        $font-family: 'Open Sans'
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$kk-app-primary: mat.define-palette($kk-blue-palette, 800);
$kk-app-accent:  mat.define-palette($kk-rose-palette, 200, 100, 500);

// The warn palette is optional (defaults to red).
$kk-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$kk-app-theme: mat.define-light-theme($kk-app-primary, $kk-app-accent, $kk-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($kk-app-theme);

// Define an alternate dark theme.
$kk-dark-primary: mat.define-palette($kk-darkblue-palette, 50);
$kk-dark-theme:   mat.define-dark-theme($kk-dark-primary, $kk-app-accent, $kk-app-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.kk-mat-dark-theme {
  @include mat.all-component-themes($kk-dark-theme);
}