.category-packages {
  margin-left: 4em;
}

.fileSize {
  color: #6e6e6e;
}

.opendata-beta {
  font-size: 50%;
  padding: .2em .6em;
  margin-left: 1ex;
}

.opendata_promote_tools_icon:before {
  font-family: FontAwesome;
  content: "\f02b";
  font-weight: 500;
}

.opendata_promote_tools:before {
  content: "DAM (2019-2021)";
}

