@import "variables";
@import "../colors";

html.no-touch .overlay {
  &.toolbar div, &.page-navigation div, .form-handle div, &.pager .pager-nav {
    &:hover {
      color: $kkDarkRose;
    }
  }
}

body.clipview {
  background-color: white;
  overflow-x: hidden;
}
