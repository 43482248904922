@media screen {
  .thumbnail > img, .thumbnail a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .dropdown-menu {
    width: inherit;
  }
  [ng\:cloak], [ng-cloak], .ng-cloak, .translate-cloak {
    display: none !important;
  }
  .container-full-width {
    padding: 0 15px;
  }
  .container-almost-full-width {
    padding: 0 42px;
  }
  .btn-kk {
    color: #61686f;
    background-color: #f8f9fa;
    background-image: none;
    border-color: #ced4da;
  }
  .btn-kk:hover {
    color: #313539;
    border-color: #94a1ae;
    background-color: #e9ecef;
  }
  .btn-kk:focus, .btn-kk.focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 104, 111, 0.5);
  }
  .btn-kk.disabled, .btn-kk:disabled {
    color: #61686f;
    background-color: transparent;
  }
  .btn-kk-blue {
    color: white;
    background-color: #0D2FC4;
    background-image: none;
    border-color: #0D2FC4;
    font-weight: normal;
    border-radius: 4px;
  }
  .btn-kk-blue:hover {
    color: white;
    background-color: #002855;
    border-color: #002855;
  }
  .btn-kk-blue:focus, .btn-kk-blue.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-blue.disabled, .btn-kk-blue:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-gray {
    color: #002855;
    background-color: #DAE0E6;
    background-image: none;
    border-color: #DAE0E6;
    font-weight: normal;
  }
  .btn-kk-gray:hover {
    color: #002855;
    background-color: #C2CBD6;
    border-color: #C2CBD6;
  }
  .btn-kk-gray:focus, .btn-kk-gray.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-gray.disabled, .btn-kk-gray:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-teal {
    color: #002855;
    background-color: #C0DDE6;
    background-image: none;
    border-color: #C0DDE6;
    font-weight: normal;
  }
  .btn-kk-teal:hover {
    color: white;
    background-color: #1C7291;
    border-color: #1C7291;
  }
  .btn-kk-teal:focus, .btn-kk-teal.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-teal.disabled, .btn-kk-teal:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-light {
    color: #002855;
    background-color: white;
    background-image: none;
    border-color: white;
    font-weight: normal;
  }
  .btn-kk-light:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .btn-kk-light:focus, .btn-kk-light.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-light.disabled, .btn-kk-light:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-border {
    border-color: #0B2852;
  }
  .btn-kk-red {
    color: white;
    background-color: #D10F0F;
    background-image: none;
    border-color: #D10F0F;
    font-weight: normal;
  }
  .btn-kk-red:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .btn-kk-red:focus, .btn-kk-red.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-red.disabled, .btn-kk-red:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-rose {
    color: #002855;
    background-color: #F4D9D5;
    background-image: none;
    border-color: #F4D9D5;
    font-weight: normal;
  }
  .btn-kk-rose:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .btn-kk-rose:focus, .btn-kk-rose.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-rose.disabled, .btn-kk-rose:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .badge-kk-blue {
    color: #fff;
    background-color: #0e65a7;
  }
  .badge-kk-blue[href]:focus, .badge-kk-blue[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0e65a7;
  }
  .btn-link, .btn-kk-link {
    font-weight: 400;
    color: #0D2FC4;
    text-decoration: none;
  }
  .btn-link:hover, .btn-kk-link:hover {
    color: #002855;
  }
  .page-link {
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    line-height: 2.3rem;
    padding: 0 12px;
    color: #0D2FC4;
  }
  .page-link:hover {
    color: #002855;
  }
  .page-item.active .page-link {
    background-color: #0D2FC4;
    border-color: #0D2FC4;
  }
  .page-item.disabled:hover {
    cursor: not-allowed;
  }
  ngb-datepicker {
    width: auto !important;
  }
  ngb-datepicker ngb-datepicker-navigation-select {
    width: 10rem !important;
  }
  ngb-datepicker ngb-datepicker-navigation-select select:first-of-type {
    width: 50% !important;
    margin-right: 0.3rem;
  }
  ngb-datepicker ngb-datepicker-navigation-select select:last-of-type {
    width: 45% !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /**
   * Box with mostly non-link colors, but with blue link colors and behaviour added to certain elements marked with
   * .link-colors (recommended for the header).
   */
}
@media screen and (max-width: 810px) {
  .atlwdg-popup {
    width: 97% !important;
    height: 97% !important;
    max-height: none !important;
  }
}
@media screen {
  .form-control {
    color: #002855;
  }
  .form-control:focus {
    color: #002855;
  }
  .form-control::placeholder {
    color: #002855;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #002855;
  }
  .nav-tabs.kk-nav-tabs {
    border-color: #ced4da;
  }
  .nav-tabs.kk-nav-tabs .nav-link:hover {
    border-color: #94a1ae #94a1ae #ced4da;
  }
  .nav-tabs.kk-nav-tabs .nav-link.active {
    border-color: #ced4da #ced4da #fff;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 33.3px !important;
  }
  .mat-button-toggle-checked {
    background-color: #0D2FC4 !important;
    color: white !important;
  }
  html {
    font-size: 14px;
  }
  body {
    color: #002855;
    font-family: "Open Sans", Helvetica, Arial, sans-serif, Nimbus Sans L;
  }
  body.article {
    background: white;
  }
  section {
    padding-bottom: 0;
  }
  section.container {
    padding-top: 20px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  h1, h2 {
    font-family: "DM Serif Display", serif;
    font-weight: 400;
  }
  h3, h4 {
    text-transform: uppercase;
    font-family: "Open Sans", Helvetica, Arial, sans-serif, Nimbus Sans L;
    font-weight: 400;
  }
  h4 {
    font-family: "Public Sans", "Open Sans", Helvetica, Arial, sans-serif, Nimbus Sans L;
    font-size: 150%;
    text-transform: none;
  }
  .h1-front {
    color: #002855;
    text-shadow: none;
    padding-bottom: 0.5rem;
  }
  .h1-front-b {
    color: #002855;
    text-shadow: none;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
  }
  .slick-prev:before, .slick-next:before {
    color: #09529b !important;
    width: 30px;
    border-style: solid;
    border-color: #002855;
    border-width: thin;
    background: #fff;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
  }
  * {
    min-height: 0;
    min-width: 0;
  }
  caption {
    color: #002855;
  }
  a.toclink {
    text-decoration: underline;
  }
  a.textwu {
    text-decoration: underline;
  }
  .sitemap_indent {
    margin-left: 20%;
  }
  a.link_with_underline {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
  a {
    color: #0D2FC4;
  }
  a:active {
    color: #1133BC;
  }
  a:hover {
    color: #002855;
  }
  a.visitable:visited {
    color: #651c85;
  }
  a.light {
    color: white;
    font-weight: bold;
    /* focus outlines would take a lot of work
    &:focus, &:focus-visible {
      color: $kkDarkRose;
    }
    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 5px;
    }*/
  }
  a.light:hover {
    color: #E29D92;
  }
  em {
    color: #AA0b0b;
    font-weight: bold;
  }
  label input[type=checkbox] {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
  }
  header.modal-title {
    font-size: 18px;
  }
  p {
    margin: 0 0 15px 0;
    font-size: 1.1rem;
  }
  .dropdown-menu {
    width: 100%;
  }
  .popover-content {
    padding: 5px;
  }
}
@media screen and (max-width: 992px) {
  .container {
    width: auto;
  }
}
@media screen {
  .btn-success {
    background-color: #648028;
    border-color: #648028;
  }
  .btn-success:hover {
    background-color: #88ae37;
    border-color: #88ae37;
  }
  .btn-info {
    background-color: #128091;
  }
  .unselectable {
    user-select: none;
  }
  .print-only {
    display: none;
  }
  .login-countdown {
    color: slategray;
  }
  button.haka {
    padding: 0.02rem;
  }
  input[type=text].ng-invalid, input[type=number].ng-invalid {
    background-color: #ffe9e9;
  }
  .fp-material-categories {
    padding-top: 0.5rem;
  }
  .fp-material-categories .row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .fp-material-categories .row .banner {
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }
  .fp-info-texts h3 {
    margin-top: 1rem;
  }
  .sp-info-text {
    font-size: 18px;
  }
  .come-closer {
    position: relative;
    z-index: 1;
  }
  .alert-warning {
    background-color: #FFFFE0;
  }
  .kk-bg-grey {
    background-color: #DFE5ED;
    color: white;
  }
  .kk-bg-grey a {
    color: white;
    font-weight: bold;
    /* focus outlines would take a lot of work
    &:focus, &:focus-visible {
      color: $kkDarkRose;
    }
    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 5px;
    }*/
  }
  .kk-bg-grey a:hover {
    color: #E29D92;
  }
  .kk-bg-grey button.btn-kk-auto, .kk-bg-grey a.btn-kk-auto {
    color: #002855;
    background-color: white;
    background-image: none;
    border-color: white;
    font-weight: normal;
  }
  .kk-bg-grey button.btn-kk-auto:hover, .kk-bg-grey a.btn-kk-auto:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .kk-bg-grey button.btn-kk-auto:focus, .kk-bg-grey button.btn-kk-auto.focus, .kk-bg-grey a.btn-kk-auto:focus, .kk-bg-grey a.btn-kk-auto.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-grey button.btn-kk-auto.disabled, .kk-bg-grey button.btn-kk-auto:disabled, .kk-bg-grey a.btn-kk-auto.disabled, .kk-bg-grey a.btn-kk-auto:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .kk-bg-grey button.btn-kk-auto-accent, .kk-bg-grey a.btn-kk-auto-accent {
    color: #002855;
    background-color: #C0DDE6;
    background-image: none;
    border-color: #C0DDE6;
    font-weight: normal;
  }
  .kk-bg-grey button.btn-kk-auto-accent:hover, .kk-bg-grey a.btn-kk-auto-accent:hover {
    color: white;
    background-color: #1C7291;
    border-color: #1C7291;
  }
  .kk-bg-grey button.btn-kk-auto-accent:focus, .kk-bg-grey button.btn-kk-auto-accent.focus, .kk-bg-grey a.btn-kk-auto-accent:focus, .kk-bg-grey a.btn-kk-auto-accent.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-grey button.btn-kk-auto-accent.disabled, .kk-bg-grey button.btn-kk-auto-accent:disabled, .kk-bg-grey a.btn-kk-auto-accent.disabled, .kk-bg-grey a.btn-kk-auto-accent:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .kk-bg-grey .nav-tabs {
    color: red;
  }
  .kk-bg-grey.kk-bg-translucent {
    background-color: rgba(0, 34, 79, 0.95);
  }
  .kk-bg-dark {
    background-color: #002855;
    color: white;
  }
  .kk-bg-dark a {
    color: white;
    font-weight: bold;
    /* focus outlines would take a lot of work
    &:focus, &:focus-visible {
      color: $kkDarkRose;
    }
    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 5px;
    }*/
  }
  .kk-bg-dark a:hover {
    color: #E29D92;
  }
  .kk-bg-dark button.btn-kk-auto, .kk-bg-dark a.btn-kk-auto {
    color: #002855;
    background-color: white;
    background-image: none;
    border-color: white;
    font-weight: normal;
  }
  .kk-bg-dark button.btn-kk-auto:hover, .kk-bg-dark a.btn-kk-auto:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .kk-bg-dark button.btn-kk-auto:focus, .kk-bg-dark button.btn-kk-auto.focus, .kk-bg-dark a.btn-kk-auto:focus, .kk-bg-dark a.btn-kk-auto.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-dark button.btn-kk-auto.disabled, .kk-bg-dark button.btn-kk-auto:disabled, .kk-bg-dark a.btn-kk-auto.disabled, .kk-bg-dark a.btn-kk-auto:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .kk-bg-dark button.btn-kk-auto-accent, .kk-bg-dark a.btn-kk-auto-accent {
    color: #002855;
    background-color: #C0DDE6;
    background-image: none;
    border-color: #C0DDE6;
    font-weight: normal;
  }
  .kk-bg-dark button.btn-kk-auto-accent:hover, .kk-bg-dark a.btn-kk-auto-accent:hover {
    color: white;
    background-color: #1C7291;
    border-color: #1C7291;
  }
  .kk-bg-dark button.btn-kk-auto-accent:focus, .kk-bg-dark button.btn-kk-auto-accent.focus, .kk-bg-dark a.btn-kk-auto-accent:focus, .kk-bg-dark a.btn-kk-auto-accent.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-dark button.btn-kk-auto-accent.disabled, .kk-bg-dark button.btn-kk-auto-accent:disabled, .kk-bg-dark a.btn-kk-auto-accent.disabled, .kk-bg-dark a.btn-kk-auto-accent:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .kk-bg-dark .nav-tabs {
    color: red;
  }
  .kk-bg-dark.kk-bg-translucent {
    background-color: rgba(0, 34, 79, 0.95);
  }
  .kk-bg-teal {
    background-color: #1C7291;
    color: white;
  }
  .kk-bg-teal a {
    color: white;
    font-weight: bold;
    /* focus outlines would take a lot of work
    &:focus, &:focus-visible {
      color: $kkDarkRose;
    }
    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 5px;
    }*/
  }
  .kk-bg-teal a:hover {
    color: #E29D92;
  }
  .kk-bg-teal button.btn-kk-auto, .kk-bg-teal a.btn-kk-auto {
    color: #002855;
    background-color: white;
    background-image: none;
    border-color: white;
    font-weight: normal;
  }
  .kk-bg-teal button.btn-kk-auto:hover, .kk-bg-teal a.btn-kk-auto:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .kk-bg-teal button.btn-kk-auto:focus, .kk-bg-teal button.btn-kk-auto.focus, .kk-bg-teal a.btn-kk-auto:focus, .kk-bg-teal a.btn-kk-auto.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-teal button.btn-kk-auto.disabled, .kk-bg-teal button.btn-kk-auto:disabled, .kk-bg-teal a.btn-kk-auto.disabled, .kk-bg-teal a.btn-kk-auto:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .kk-bg-rose {
    background-color: #F7DEDA;
    color: white;
  }
  .kk-bg-rose a {
    color: white;
    font-weight: bold;
    /* focus outlines would take a lot of work
    &:focus, &:focus-visible {
      color: $kkDarkRose;
    }
    &:focus-visible {
      outline: 2px solid white;
      outline-offset: 5px;
    }*/
  }
  .kk-bg-rose a:hover {
    color: #E29D92;
  }
  .kk-bg-rose button.btn-kk-auto, .kk-bg-rose a.btn-kk-auto {
    color: #002855;
    background-color: white;
    background-image: none;
    border-color: white;
    font-weight: normal;
  }
  .kk-bg-rose button.btn-kk-auto:hover, .kk-bg-rose a.btn-kk-auto:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .kk-bg-rose button.btn-kk-auto:focus, .kk-bg-rose button.btn-kk-auto.focus, .kk-bg-rose a.btn-kk-auto:focus, .kk-bg-rose a.btn-kk-auto.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-rose button.btn-kk-auto.disabled, .kk-bg-rose button.btn-kk-auto:disabled, .kk-bg-rose a.btn-kk-auto.disabled, .kk-bg-rose a.btn-kk-auto:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .kk-bg-light {
    background-color: white;
    color: #002855;
  }
  .kk-bg-light a {
    color: #0D2FC4;
  }
  .kk-bg-light a:active {
    color: #1133BC;
  }
  .kk-bg-light a:hover {
    color: #002855;
  }
  .kk-bg-light a.visitable:visited {
    color: #651c85;
  }
  .kk-bg-light button.btn-kk-auto, .kk-bg-light a.btn-kk-auto {
    color: white;
    background-color: #0D2FC4;
    background-image: none;
    border-color: #0D2FC4;
    font-weight: normal;
  }
  .kk-bg-light button.btn-kk-auto:hover, .kk-bg-light a.btn-kk-auto:hover {
    color: white;
    background-color: #002855;
    border-color: #002855;
  }
  .kk-bg-light button.btn-kk-auto:focus, .kk-bg-light button.btn-kk-auto.focus, .kk-bg-light a.btn-kk-auto:focus, .kk-bg-light a.btn-kk-auto.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-light button.btn-kk-auto.disabled, .kk-bg-light button.btn-kk-auto:disabled, .kk-bg-light a.btn-kk-auto.disabled, .kk-bg-light a.btn-kk-auto:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .kk-bg-lightgray {
    background-color: white;
    color: #002855;
    background-color: #EFF1F4;
  }
  .kk-bg-lightgray a {
    color: #0D2FC4;
  }
  .kk-bg-lightgray a:active {
    color: #1133BC;
  }
  .kk-bg-lightgray a:hover {
    color: #002855;
  }
  .kk-bg-lightgray a.visitable:visited {
    color: #651c85;
  }
  .kk-bg-lightgray button.btn-kk-auto, .kk-bg-lightgray a.btn-kk-auto {
    color: white;
    background-color: #0D2FC4;
    background-image: none;
    border-color: #0D2FC4;
    font-weight: normal;
  }
  .kk-bg-lightgray button.btn-kk-auto:hover, .kk-bg-lightgray a.btn-kk-auto:hover {
    color: white;
    background-color: #002855;
    border-color: #002855;
  }
  .kk-bg-lightgray button.btn-kk-auto:focus, .kk-bg-lightgray button.btn-kk-auto.focus, .kk-bg-lightgray a.btn-kk-auto:focus, .kk-bg-lightgray a.btn-kk-auto.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .kk-bg-lightgray button.btn-kk-auto.disabled, .kk-bg-lightgray button.btn-kk-auto:disabled, .kk-bg-lightgray a.btn-kk-auto.disabled, .kk-bg-lightgray a.btn-kk-auto:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .dark-visitable-links a:visited {
    color: #651c85;
  }
  .kk-tooltip-wide .tooltip-inner {
    max-width: 60vw;
  }
  .table.tblth {
    font-weight: 600;
    border-bottom: #002855;
    border-bottom-width: 1px;
  }
  section.faq.container {
    padding-top: 0px;
  }
  .kk-input-effects {
    position: relative;
  }
  .kk-input-effects .kk-input-indicator {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.3rem;
  }
  .link-icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    padding-right: 1em;
  }
  .link-arrow::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    content: "\f178";
  }
  .link-arrow:hover::before {
    transform: translateX(5px);
  }
  .kk-play {
    color: #002855;
  }
  .kk-play:hover {
    color: #A0270A;
  }
  a {
    text-decoration: none;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .dropdown-menu {
    width: inherit;
  }
  [ng\:cloak], [ng-cloak], .ng-cloak, .translate-cloak {
    display: none !important;
  }
  .container-full-width {
    padding: 0 15px;
  }
  .container-almost-full-width {
    padding: 0 42px;
  }
  .btn-kk {
    color: #61686f;
    background-color: #f8f9fa;
    background-image: none;
    border-color: #ced4da;
  }
  .btn-kk:hover {
    color: #313539;
    border-color: #94a1ae;
    background-color: #e9ecef;
  }
  .btn-kk:focus, .btn-kk.focus {
    box-shadow: 0 0 0 0.2rem rgba(97, 104, 111, 0.5);
  }
  .btn-kk.disabled, .btn-kk:disabled {
    color: #61686f;
    background-color: transparent;
  }
  .btn-kk-blue {
    color: white;
    background-color: #0D2FC4;
    background-image: none;
    border-color: #0D2FC4;
    font-weight: normal;
    border-radius: 4px;
  }
  .btn-kk-blue:hover {
    color: white;
    background-color: #002855;
    border-color: #002855;
  }
  .btn-kk-blue:focus, .btn-kk-blue.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-blue.disabled, .btn-kk-blue:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-gray {
    color: #002855;
    background-color: #DAE0E6;
    background-image: none;
    border-color: #DAE0E6;
    font-weight: normal;
  }
  .btn-kk-gray:hover {
    color: #002855;
    background-color: #C2CBD6;
    border-color: #C2CBD6;
  }
  .btn-kk-gray:focus, .btn-kk-gray.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-gray.disabled, .btn-kk-gray:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-teal {
    color: #002855;
    background-color: #C0DDE6;
    background-image: none;
    border-color: #C0DDE6;
    font-weight: normal;
  }
  .btn-kk-teal:hover {
    color: white;
    background-color: #1C7291;
    border-color: #1C7291;
  }
  .btn-kk-teal:focus, .btn-kk-teal.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-teal.disabled, .btn-kk-teal:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-light {
    color: #002855;
    background-color: white;
    background-image: none;
    border-color: white;
    font-weight: normal;
  }
  .btn-kk-light:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .btn-kk-light:focus, .btn-kk-light.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-light.disabled, .btn-kk-light:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-border {
    border-color: #0B2852;
  }
  .btn-kk-red {
    color: white;
    background-color: #D10F0F;
    background-image: none;
    border-color: #D10F0F;
    font-weight: normal;
  }
  .btn-kk-red:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .btn-kk-red:focus, .btn-kk-red.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-red.disabled, .btn-kk-red:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .btn-kk-rose {
    color: #002855;
    background-color: #F4D9D5;
    background-image: none;
    border-color: #F4D9D5;
    font-weight: normal;
  }
  .btn-kk-rose:hover {
    color: #002855;
    background-color: #E29D92;
    border-color: #E29D92;
  }
  .btn-kk-rose:focus, .btn-kk-rose.focus {
    box-shadow: none;
    outline: 2px solid #0D2FC4;
    outline-offset: 3px;
  }
  .btn-kk-rose.disabled, .btn-kk-rose:disabled {
    color: #002855;
    background-color: #DAE0E6;
    border-color: #DAE0E6;
    font-weight: 300;
    cursor: not-allowed;
  }
  .badge-kk-blue {
    color: #fff;
    background-color: #0e65a7;
  }
  .badge-kk-blue[href]:focus, .badge-kk-blue[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0e65a7;
  }
  .btn-link, .btn-kk-link {
    font-weight: 400;
    color: #0D2FC4;
    text-decoration: none;
  }
  .btn-link:hover, .btn-kk-link:hover {
    color: #002855;
  }
  .page-link {
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    line-height: 2.3rem;
    padding: 0 12px;
    color: #0D2FC4;
  }
  .page-link:hover {
    color: #002855;
  }
  .page-item.active .page-link {
    background-color: #0D2FC4;
    border-color: #0D2FC4;
  }
  .page-item.disabled:hover {
    cursor: not-allowed;
  }
  ngb-datepicker {
    width: auto !important;
  }
  ngb-datepicker ngb-datepicker-navigation-select {
    width: 10rem !important;
  }
  ngb-datepicker ngb-datepicker-navigation-select select:first-of-type {
    width: 50% !important;
    margin-right: 0.3rem;
  }
  ngb-datepicker ngb-datepicker-navigation-select select:last-of-type {
    width: 45% !important;
  }
}
@media screen and (max-width: 810px) {
  .atlwdg-popup {
    width: 97% !important;
    height: 97% !important;
    max-height: none !important;
  }
}
@media screen {
  .form-control {
    color: #002855;
  }
  .form-control:focus {
    color: #002855;
  }
  .form-control::placeholder {
    color: #002855;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #002855;
  }
  .nav-tabs.kk-nav-tabs {
    border-color: #ced4da;
  }
  .nav-tabs.kk-nav-tabs .nav-link:hover {
    border-color: #94a1ae #94a1ae #ced4da;
  }
  .nav-tabs.kk-nav-tabs .nav-link.active {
    border-color: #ced4da #ced4da #fff;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 33.3px !important;
  }
  .mat-button-toggle-checked {
    background-color: #0D2FC4 !important;
    color: white !important;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  html.no-touch .overlay.toolbar div:hover, html.no-touch .overlay.page-navigation div:hover, html.no-touch .overlay .form-handle div:hover, html.no-touch .overlay.pager .pager-nav:hover {
    color: #E29D92;
  }
  body.clipview {
    background-color: white;
    overflow-x: hidden;
  }
  .browse-search-filters:after {
    content: "";
    display: table;
    clear: both;
  }
  .browse-search-filters .browse-search-checkboxes {
    text-align: left;
    top: -4px;
  }
  .browse-search-filters .browse-search-checkboxes label {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
  .browse-all-titles .digitization-years-row {
    margin-left: 10px;
    display: block;
  }
  .browse-all-titles .digitization-years-available, .browse-all-titles .digitization-years-allowed {
    margin-right: 5px;
  }
  .browse-all-titles .digitization-years-allowed i {
    color: #00A000;
  }
  .browse-all-titles .digitization-years-restricted {
    color: #696969;
  }
  .browse-all-titles ul li:after {
    content: "";
    display: table;
    clear: both;
  }
  .browse-all-titles ul li .title-basic-info {
    float: left;
    margin-top: 4px;
  }
  .browse-all-titles ul li .title-basic-info .name {
    font-size: 16px;
  }
  .browse-all-titles ul li .title-basic-info i.allowed {
    color: limegreen;
  }
  .browse-all-titles ul li .title-details {
    float: right;
    text-align: right;
  }
  .browse-all-titles ul li .title-details .identification {
    margin-top: 4px;
    margin-right: 1em;
    float: right;
    font-size: 10px;
  }
  .browse-all-titles ul li .title-details .identification a {
    font-size: 16px;
    vertical-align: middle;
  }
  .browse-all-titles ul li .title-details .content-amount-indicators {
    font-size: 10px;
    color: #666;
    float: right;
    width: 80px;
    text-align: center;
  }
  .browse-all-titles ul li .title-details .content-amount-indicators > * {
    display: block;
  }
  .progress-bar-indicator {
    display: inline-block;
    width: 80px;
    height: 5px;
    border: 1px solid #aaa;
    vertical-align: middle;
  }
  .progress-bar-indicator > span {
    display: block;
    background-color: #98D604;
    height: 100%;
  }
  .page-count-indicator {
    font-size: 7px;
  }
  .page-count-indicator i {
    margin-left: 0.5px;
    margin-right: 0.5px;
  }
  @-webkit-keyframes goldenFlash {
    from {
      color: #428BCA;
    }
    75% {
      color: #428BCA;
    }
    80% {
      color: #eae166;
    }
    85% {
      color: #428BCA;
    }
    to {
      color: #428BCA;
    }
  }
  @-moz-keyframes goldenFlash {
    from {
      color: #428BCA;
    }
    75% {
      color: #428BCA;
    }
    80% {
      color: #eae166;
    }
    85% {
      color: #428BCA;
    }
    to {
      color: #428BCA;
    }
  }
  @-ms-keyframes goldenFlash {
    from {
      color: #428BCA;
    }
    75% {
      color: #428BCA;
    }
    80% {
      color: #eae166;
    }
    85% {
      color: #428BCA;
    }
    to {
      color: #428BCA;
    }
  }
  @keyframes goldenFlash {
    from {
      color: #428BCA;
    }
    75% {
      color: #428BCA;
    }
    80% {
      color: #eae166;
    }
    85% {
      color: #428BCA;
    }
    to {
      color: #428BCA;
    }
  }
  .new-bindings-indicator {
    color: #428BCA;
    animation-name: goldenFlash;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
  .category-packages {
    margin-left: 4em;
  }
  .fileSize {
    color: #6e6e6e;
  }
  .opendata-beta {
    font-size: 50%;
    padding: 0.2em 0.6em;
    margin-left: 1ex;
  }
  .opendata_promote_tools_icon:before {
    font-family: FontAwesome;
    content: "\f02b";
    font-weight: 500;
  }
  .opendata_promote_tools:before {
    content: "DAM (2019-2021)";
  }
  .search-highlight-fragment + .search-highlight-fragment {
    margin-top: 1rem;
  }
  .text-with-thumb-image-container {
    width: 225px;
  }
  .text-with-thumb-image {
    width: 200px;
  }
  .cursor-progress {
    cursor: progress !important;
  }
  .mouse-crosshair {
    cursor: crosshair;
  }
  .mouse-move {
    cursor: move;
  }
  .mouse-ns-resize {
    cursor: ns-resize;
  }
  .mouse-ew-resize {
    cursor: ew-resize;
  }
  .mouse-nesw-resize {
    cursor: nesw-resize;
  }
  .mouse-nwse-resize {
    cursor: nwse-resize;
  }
  .form-saving-true, .form-saving-true input, .form-saving-true button {
    cursor: progress !important;
  }
  div.caption {
    padding-top: 2rem;
  }
  div.caption h3 {
    font-size: 1.2rem;
  }
  .absolute-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .overlay {
    z-index: 11;
  }
  time {
    white-space: nowrap;
  }
  .multi-column-results {
    list-style-type: none;
    padding-left: 0;
    column-width: 20em;
  }
  .two-column-results {
    column-count: 2;
  }
  .two-column-results li, .two-column-results .list-group-item, .multi-column-results li, .multi-column-results .list-group-item {
    break-inside: avoid;
  }
  .year-columns {
    margin: 0 auto;
    width: 30em;
    column-count: 6;
  }
  ul.alphabet-columns, ul.centuries, ul.years {
    margin: 1em;
    text-align: center;
    list-style-type: none;
  }
  ul.alphabet-columns > li, ul.centuries > li, ul.years > li {
    display: inline;
    margin-right: 1em;
  }
  ul.alphabet-columns > li a, ul.centuries > li a, ul.years > li a {
    font-weight: bold;
  }
  .validation-errors {
    color: #ac0000;
  }
  .saved-item {
    margin-bottom: 15px;
  }
  .personal-notes {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  input.error {
    border: 1px solid red;
  }
  .puls {
    animation: pulsate 4s linear infinite;
  }
  @keyframes pulsate {
    0% {
      color: #ddd;
      text-shadow: 0 -1px 0 #000;
    }
    50% {
      color: #fff;
      text-shadow: 0 -1px 0 #444, 0 0 5px #ffd, 0 0 8px #fff;
    }
    100% {
      color: #ddd;
      text-shadow: 0 -1px 0 #000;
    }
  }
  .userarticlesearch {
    margin: 0 auto;
    padding-bottom: 11px;
    padding-top: 11px;
    width: 96%;
    clear: both;
  }
  .scrapbook-items {
    list-style-type: none;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .scrapbook-items > li:nth-child(2n+1) {
    background-color: #d9d3c5;
  }
  .scrapbook-items > li.hover-true {
    text-decoration: underline;
  }
  .searcharea {
    width: 250px;
  }
  .list-group-item {
    background-color: transparent;
  }
  .forgotten-links {
    margin: auto;
    text-align: center;
  }
  .forgotten-links a {
    margin: 0 10px;
  }
  .required-indicator {
    color: #D10000;
  }
  .opendata-usage {
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  .opendata-usage:not(:last-child) {
    margin-bottom: 1ex;
  }
  .opendata-usage:after {
    content: "";
    display: table;
    clear: both;
  }
  .account-section {
    padding-top: 1rem;
  }
  .other-section h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .media {
    display: flex;
    align-items: flex-start;
  }
  .media-body {
    flex: 1;
  }
  .float-right {
    float: right !important;
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  .fa-regular, .far {
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
  }
  .fa {
    font-family: var(--fa-style-family, "Font Awesome 6 Pro");
    font-weight: var(--fa-style, 900);
  }
  .popover-picker .popover {
    max-width: none;
  }
}
@media print {
  * {
    margin: 0;
    padding: 0;
    border: none;
  }
  video, audio, object, embed, iframe {
    display: none;
  }
  header.brand, .search-drawer, .breadcrumb, .search-methods {
    display: none;
  }
  .tooltip {
    display: none;
  }
  html, body, .js-content {
    height: 100%;
  }
  .print-only.header {
    text-align: center;
    font-size: 12px;
    margin-bottom: 1ex;
    height: 15px;
  }
  .print-only.header:after {
    content: "";
    display: table;
    clear: both;
  }
  .print-only.header .left {
    float: left;
  }
  .print-only.header .right {
    float: right;
  }
  .overlay.toolbar, .overlay.citation-info, .overlay.page-navigation, .overlay.overlay-form, #bindingProblemsModal, .image-overlay .overlay.restricted-warning {
    display: none;
  }
  .user-article {
    display: none;
  }
  div[ng-controller=BindingRootController] {
    height: calc(100% - 25px);
    width: 100%;
  }
  div[ng-controller=BindingController], section.page-holder {
    height: 100%;
  }
  .image-wrapper {
    width: 100% !important;
    height: 100% !important;
    text-align: center;
  }
  .image-wrapper img {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .clipping-view a {
    text-decoration: none;
  }
  .clipping-view a img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
  .tabs-container, .clipping-actions, input, .write-comment, .citation-info {
    display: none;
  }
  .clipping-metadata h1 {
    font-size: large;
  }
  .clipping-metadata div {
    height: auto !important;
    margin: 0 !important;
  }
  .browse-all-titles .list-group-item:after {
    content: "";
    display: table;
    clear: both;
  }
  .browse-all-titles .list-group-item .title-details {
    float: right;
  }
  .browse-all-titles .list-group-item .title-details:after {
    content: "";
    display: table;
    clear: both;
  }
  .browse-all-titles .list-group-item .title-details .content-amount-indicators {
    margin-right: 2em;
    min-width: 5em;
    display: inline-block;
    text-align: right;
  }
  .browse-all-titles .list-group-item .title-details .identification {
    margin-left: 4em;
    min-width: 5em;
    display: inline-block;
  }
  .browse-all-titles .list-group-item a[href]:after {
    content: none;
  }
  .browse-all-titles .list-group-item .identification a, .browse-all-titles .list-group-item .page-count-indicator {
    display: none;
  }
}