
@import "../mixins";
@import "../animations";

$indicatorWidth: 80px;

.browse-search-filters {
  @include clearFix;

  .browse-search-checkboxes {
    text-align: left;

    // Hack: we need to vertically align the search boxes and this one doesn't quite fit.
    // We could add more space to the others, but our header is already quite large.
    top: -4px;

    label {
      margin: 0;
      padding: 0;
      font-weight: normal;
    }
  }
}

.browse-all-titles {

  .digitization-years-row {
    margin-left: 10px;
    display: block;
  }

  .digitization-years-available, .digitization-years-allowed {
    margin-right: 5px;
  }

  .digitization-years-allowed {
    i {
      color: #00A000;
    }
  }

  .digitization-years-restricted {
    color: #696969; //#aaa;
  }

  ul li {
    @include clearFix;

    .title-basic-info {
      float: left;
      margin-top: 4px; // align the title name to the middle vertically

      .name {
        font-size: 16px;
      }

      i.allowed {
        color: limegreen;
      }
    }

    .title-details {
      float: right;
      text-align: right;

      .identification {
        margin-top: 4px; // align the identification to the middle vertically, related to content amount indicators
        margin-right: 1em;
        float: right;
        font-size: 10px;

        a {
          font-size: 16px;
          vertical-align: middle;
        }
      }

      .content-amount-indicators {
        font-size: 10px;
        color: #666;
        float: right;
        width: $indicatorWidth;
        text-align: center;

        > * {
          display: block;
        }
      }
    }
  }
}

.progress-bar-indicator {
  display: inline-block;
  width: $indicatorWidth;
  height: 5px;
  border: 1px solid #aaa;
  vertical-align: middle;

  > span {
    display: block;
    background-color: #98D604;
    height: 100%;
  }
}

.page-count-indicator {
  font-size: 7px;

  i {
    margin-left: 0.5px;
    margin-right: 0.5px;
  }
}

$starColor: #428BCA;
$goldenFlashColor: #eae166;

@include colorFlashKeyFrames(goldenFlash, $starColor, $goldenFlashColor);

.new-bindings-indicator {
  color: $starColor;

  @include colorFlash(goldenFlash);
}
