@import "mixins";
@import "colors";

/**
 * Box with mostly non-link colors, but with blue link colors and behaviour added to certain elements marked with
 * .link-colors (recommended for the header).
 */
@mixin kkSearchResultBox {
  a {
    display: block;
    padding: 0.5rem;
    color: $kkDarkBlue2021;

    &:hover {
      background: $kkLightGray2021;
      text-decoration: none;

      .link-colors {
        color: $kkDarkBlue2021;
        text-decoration: underline;
      }
    }

    .link-colors {
      color: $kkBlue2021;
    }

    &:visited .link-colors {
      color: $kkLinkVisited2021;
    }
  }
}

.search-highlight-fragment+.search-highlight-fragment {
  margin-top: 1rem;
}

.text-with-thumb-image-container {
 width: 225px;
}

.text-with-thumb-image {
  width: 200px;
}