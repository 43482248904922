
.other-section {
  h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.float-right {
  float: right !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.fa-regular, .far {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}