
// internal helper
@mixin keyFrames($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-ms-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin colorFlashKeyFrames($animationName, $baseColor, $flashColor) {

  @include keyFrames($animationName) {
    from { color: $baseColor; }
    75% { color: $baseColor; }
    80% { color: $flashColor; }
    85% { color: $baseColor; }
    to { color: $baseColor; }
  }
}

// requires animation generated with colorFlashKeyFrames mixin
@mixin colorFlash($animationName) {
  animation-name: $animationName;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
