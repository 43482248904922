@import "./colors";
@import "./_library_integrations.scss";

html {
  // bootstrap 4 assumes default font size of 16px, but our old used 14px
  font-size: 14px;
}


body {
  color: $kkDarkBlue2021;
  font-family: "Open Sans",Helvetica,Arial,sans-serif,Nimbus Sans L;
}

body.article {
  background: white;
}

section {
  padding-bottom: 0;
}

section.container {
  padding-top: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

h1, h2 {
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
}

h3, h4 {
  text-transform: uppercase;
  font-family: "Open Sans",Helvetica,Arial,sans-serif,Nimbus Sans L;
  font-weight: 400;
}

h4 {
  font-family: "Public Sans", "Open Sans",Helvetica,Arial,sans-serif,Nimbus Sans L;
  font-size: 150%;
  text-transform: none;
}

.h1-front {
  color: $kkDarkBlue2021;
  text-shadow: none;
  padding-bottom: 0.5rem;
}

.h1-front-b {
  color: $kkDarkBlue2021;
  text-shadow: none;
  padding-bottom: 1.5rem;
  padding-top: 1rem;

}

.slick-prev:before, .slick-next:before {
  color: #09529b !important;
  width: 30px;
  border-style: solid;
  border-color: #002855;
  border-width: thin;
  background: #fff;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
}


// fix slick flexbox problem https://github.com/kenwheeler/slick/issues/982#issuecomment-73517458
*{
  min-height: 0;
  min-width: 0;
}

caption {
  color: $kkDarkBlue2021;
}

@mixin darkLink {
  color: $kkLink2021;

  &:active {
    color: $kkLinkActive2021;
  }

  &:hover {
    color: $kkDarkBlue2021;
  }

  &.visitable:visited {
    color: $kkLinkVisited2021;
  }
}

@mixin lightLink {
  color: white;
  font-weight: bold;

  &:hover {
    color: $kkDarkRose;
  }

  /* focus outlines would take a lot of work
  &:focus, &:focus-visible {
    color: $kkDarkRose;
  }
  &:focus-visible {
    outline: 2px solid white;
    outline-offset: 5px;
  }*/
}

a.toclink {
 text-decoration:underline ;
}

a.textwu {
  text-decoration:underline ;
}

.sitemap_indent{
  margin-left: 20%;
}

a.link_with_underline {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

a {
  @include darkLink
}

a.light {
  @include lightLink
}




em {
  color: #AA0b0b;
  font-weight: bold;
}

// https://www.geeksforgeeks.org/how-to-align-checkboxes-and-their-labels-on-cross-browsers-using-css/
label {
  input[type=checkbox] {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
  }
}

header.modal-title {
  font-size: 18px;
}

p {
    margin: 0 0 15px 0;
    // line-height: 1.8em;
    font-size: 1.1rem;

    //letter-spacing: 0.01em;
}

//.form-control {
//  border: none;
//}

.dropdown-menu {
  width: 100%;
}

.popover-content {
  padding: 5px;
}

@media (max-width: 992px) {

  .container {
    width: auto;
  }
}

.btn-success {
  background-color: #648028;
  border-color: #648028;
}

.btn-success:hover {
  background-color: #88ae37;
  border-color: #88ae37;
}

.btn-info {
  background-color: #128091;
}

.unselectable {
  user-select: none;
}

.print-only {
  display: none;
}

.login-countdown {
  color: slategray;
}

button.haka {
  padding: 0.02rem;
}

input[type="text"].ng-invalid, input[type="number"].ng-invalid {
  background-color: #ffe9e9;
}

.fp-material-categories {
  padding-top: 0.5rem;

  .row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .banner {
      margin-right: 1.5rem;
      margin-bottom: 1rem;
    }

    .stats {

    }
  }
}

.fp-info-texts {
  h3 {
    margin-top: 1rem;
  }
}

.sp-info-text {
  font-size: 18px;
}

.come-closer {
  // tweak the stacking context just a bit so that this element displays over those with undefined context

  position: relative;
  z-index: 1;
}

.alert-warning {
  background-color: #FFFFE0;
}

@mixin darkVisitableLink {
  a:visited {
    color: $kkLinkVisited2021;
  }
}

@mixin darkTheme {
  background-color: $kkDarkBlue2021;
  color: white;

  a {
    @include lightLink
  }
  
  button.btn-kk-auto, a.btn-kk-auto {
    @include kkButtonLight
  }
  
  button.btn-kk-auto-accent, a.btn-kk-auto-accent {
    @include kkButtonTeal
  }
  
  .nav-tabs {
    color: red;
  }
  
  &.kk-bg-translucent {
    background-color: rgba($kkBgDarkBlue, 0.95);
  }
}

@mixin tealTheme {
  background-color: $kkDarkTeal;
  color: white;

  a {
    @include lightLink
  }

  button.btn-kk-auto, a.btn-kk-auto {
    @include kkButtonLight
  }
}

@mixin roseTheme {
  background-color: $kkPuuteri;
  color: white;

  a {
    @include lightLink
  }

  button.btn-kk-auto, a.btn-kk-auto {
    @include kkButtonLight
  }
}




@mixin lightTheme {
  background-color: white;
  color: $kkDarkBlue2021;
  
  a {
    @include darkLink;
  }

  button.btn-kk-auto, a.btn-kk-auto {
    @include kkButtonDarkBlue
  }
}


@mixin greyTheme {
  background-color: $kkBlue40;
  color: white;

  a {
    @include lightLink
  }

  button.btn-kk-auto, a.btn-kk-auto {
    @include kkButtonLight
  }

  button.btn-kk-auto-accent, a.btn-kk-auto-accent {
    @include kkButtonTeal
  }

  .nav-tabs {
    color: red;
  }

  &.kk-bg-translucent {
    background-color: rgba($kkBgDarkBlue, 0.95);
  }
}



.kk-bg-grey {
  @include greyTheme
}

.kk-bg-dark {
  @include darkTheme
}

.kk-bg-teal {
  @include tealTheme
}

.kk-bg-rose {
  @include roseTheme
}



.kk-bg-light {
  @include lightTheme
}

.kk-bg-lightgray {
  @include lightTheme;
  
  background-color: $kkLightGray2021;
}

.dark-visitable-links {
  @include darkVisitableLink
}

.kk-tooltip-wide {
  .tooltip-inner {
    max-width: 60vw;
  }
}

.table.tblth {
  font-weight: 600;
  border-bottom: #002855;
  border-bottom-width: 1px;
}

section.faq.container {
  padding-top: 0px;
}

.kk-input-effects {
  position: relative;

  .kk-input-indicator {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.3rem;
  }
}

.link-icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  padding-right: 1em;
}

.link-arrow::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  content: "\f178";
}

.link-arrow:hover::before {
  transform: translateX(5px);
}

.kk-play {
  color: $kkBlue100;
}

.kk-play:hover {
  color: $kkTiili;
}

a {
  text-decoration: none;
}

.form-group {
  margin-bottom: 1rem;
}